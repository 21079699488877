<!-- 合作银行详情 -->
<template>
  <div class="BankDetail">
    <div
      class="topFixed"
      ref="topFixed"
      :style="`top:${isShowDownLoad ? '0.92' : '0.44'}rem`"
    >
      <div class="top">
        <p class="name">{{ name }}</p>
        <img class="logo" :src="imgSrc" />
      </div>
      <div class="introduce">{{ funderDesc }}</div>
      <LineTab :list="tabList" :active="activeKey" @tabChange="tabChange" />
    </div>
    <CardInfo
      :style="`margin-top:${tophHeight}px`"
      v-if="hasInfo"
      :source="source[activeKey]"
    />
  </div>
</template>

<script>
import { funderInfo } from "@/api/api_home";
import LineTab from "@/components/LineTab.vue";
import CardInfo from "./components/CardInfo";
import { mapState } from "vuex";
export default {
  name: "BankDetail",
  data() {
    return {
      tophHeight: 0,
      id: this.$route.query.id,
      name: "",
      imgSrc: "",
      funderDesc: "",
      source: {},
      hasInfo: false,
      activeKey: "newCar",
      tabList: [
        { name: "新车", code: "newCar", isShow: false },
        {
          name: "二手车",
          code: "usedCar",
          isShow: false
        }
      ]
    };
  },
  computed: {
    ...mapState(["isShowDownLoad"])
  },
  created() {
    this.initData();
  },
  methods: {
    tabChange(code) {
      this.activeKey = code;
      window.scrollTo(0, 0);
    },
    initData() {
      funderInfo({ id: this.id }).then(res => {
        if (res.code === 0) {
          const info = res.data.info;
          info.bizList.forEach(item => {
            this.source[item.bizType] = item;
            if (item.bizType == "newCar") {
              this.$set(this.tabList[0], "isShow", true);
            } else {
              this.$set(this.tabList[1], "isShow", true);
            }
          });
          if (info.bizList.length === 1) {
            this.tabList.forEach(item => {
              if (item.isShow) {
                this.activeKey = item.code;
              }
            });
          }
          this.hasInfo = Object.keys(this.source).length;
          this.name = info.funderName;
          this.imgSrc = info.funderLogo;
          this.funderDesc = info.funderDesc;
          this.$nextTick(() => {
            this.tophHeight = this.$refs.topFixed.offsetHeight;
          });
        }
      });
    }
  },
  components: { LineTab, CardInfo }
};
</script>
<style lang="scss" scoped>
.BankDetail {
  background: #fff;
  padding-bottom: 0.3rem;
  .topFixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0.44rem;
    z-index: 2;
    background: #fff;
    .top {
      display: flex;
      align-items: center;
      padding: 0 0.18rem;
      .name {
        font-size: 0.3rem;
        font-weight: bold;
        line-height: 0.42rem;
      }
      .logo {
        height: 0.4rem;
        margin-left: 0.12rem;
      }
    }
    .introduce {
      color: #6e727a;
      line-height: 0.22rem;
      padding: 0.12rem 0.18rem 0;
    }
  }

  .LineTab {
    height: 0.49rem;
  }
}
</style>
