<!-- 资方详情信息 -->
<template>
  <div class="cardInfo">
    <p class="title">客户信息</p>
    <div class="table">
      <div class="row">
        <p class="left">年龄[岁]</p>
        <p class="right">
          {{ source.customerInfo.ageStart }} -
          {{ source.customerInfo.ageEnd }}
        </p>
      </div>
      <div class="row">
        <p class="left">担保人模式</p>
        <p class="right">
          {{ source.customerInfo.bondsman === "1" ? "支持" : "不支持" }}
        </p>
      </div>
    </div>
    <p class="title">车辆信息</p>
    <div class="table">
      <div class="row">
        <p class="left">车龄[年]</p>
        <p class="right">
          <span v-show="source.carInfo.carAge">≤</span>
          {{ source.carInfo.carAge }}
        </p>
      </div>
      <div class="row">
        <p class="left">乘用车</p>
        <p class="right">{{ passengerCar }}</p>
      </div>
      <div class="row">
        <p class="left">LCV车型</p>
        <p class="right">{{ LCVSuppost }}</p>
      </div>
      <div class="row">
        <p class="left">新能源车型</p>
        <p class="right">{{ newSuppost }}</p>
      </div>
    </div>
    <p class="title" v-show="source.loanInfo">融资信息</p>
    <div class="extraInfo">{{ source.loanInfo }}</div>
  </div>
</template>

<script>
export default {
  name: "cardInfo",
  props: { source: { type: Object } },
  data() {
    return { LCVSuppost: "", newSuppost: "", passengerCar: "" };
  },
  watch: {
    source: {
      handler() {
        const str = this.source.carInfo.vehicleModel.join();
        this.LCVSuppost = str.includes("LCV") ? "支持" : "不支持";
        this.newSuppost = str.includes("新能源") ? "支持" : "不支持";
        this.passengerCar = str.includes("乘用车") ? "支持" : "不支持";
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped>
.cardInfo {
  padding: 0.24rem 0.18rem;
  .title {
    font-size: 0.2rem;
    line-height: 0.28rem;
  }
  .table {
    box-shadow: inset 0 -1px 0 0 #eeeeee, inset 1px 0 0 0 #eeeeee,
      inset 0 1px 0 0 #eeeeee;
    font-size: 0.13rem;
    margin: 0.12rem 0 0.3rem;
    .row {
      height: 0.44rem;
      line-height: 0.44rem;
      display: flex;
      border-bottom: 1px solid #eeeeee;
      p {
        padding-left: 0.2rem;
        border-right: 1px solid #eeeeee;
      }
      .left {
        width: 35%;
      }
      .right {
        width: 65%;
      }
    }
  }
  .extraInfo {
    line-height: 0.22rem;
    font-size: 0.13rem;
    margin-top: 0.1rem;
    color: #181c27;
  }
}
</style>
